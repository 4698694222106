import React, { createContext, useEffect, useState } from 'react';
export const MyCreateContext = createContext();

const HandleAllApi = (props) => {
    const [login, setlogin] = useState({
        login: false,
        name: "",
        email: "",
        phone: "",
        username: "",
        description: "",
        desc: "",
        type: "",
        date: "",
        verify: "",
        socials: [{ linkedin: '', github: '', facebook: '', website: '' }],
        image: ""
    });

    const changeLogin = (change = login.login, name = login.name, email = login.email, phone = login.phone, verify = login.verify, type = login.type, username = login.username, description = login.description, desc = login.desc, date = login.date, socials = login.socials, image = login.image) => setlogin({
        login: change,
        date: date,
        email: email,
        name: name,
        phone: phone,
        type: type,
        username: username,
        description: description,
        desc: desc,
        verify: verify,
        socials: socials,
        image: image,
    });

    useEffect(() => {
        let userEmail = localStorage.getItem("google_verify_login_auth");
        let loginCount = localStorage.getItem("google_verify_login_auth_count");

        if (loginCount <= 0) {
            localStorage.setItem("google_verify_login_auth", "")
            localStorage.setItem("google_verify_login_auth_count", 0)
        } else if (userEmail && (userEmail !== "")) {
            fetch("https://api.softingart.co.in/user/check", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: userEmail }),
            }).then(js => js.json()).then(resp => {
                if (resp.status === "no user found") {
                    localStorage.setItem("google_verify_login_auth", "")
                    localStorage.setItem("google_verify_login_auth_count", 0)
                    setlogin({
                        login: false,
                        email: "",
                        type: "",
                        date: "",
                        name: "",
                        phone: "",
                        username: "",
                        description: "",
                        desc: "",
                        verify: "0",
                        socials: [{ linkedin: '', github: '', facebook: '', website: '' }],
                        image: "",
                    });
                } else {
                    setlogin({
                        login: true,
                        email: resp.status.email,
                        type: resp.status.role,
                        date: resp.status.date,
                        name: resp.status.name,
                        phone: resp.status.phone,
                        username: resp.status.username,
                        description: resp.status.description,
                        desc: resp.status.desc,
                        verify: resp.status.verify,
                        socials: resp.status.socials,
                        image: resp.status.image,
                    });
                }
            }).catch(err => console.log(err));
        }
    }, [])

    return (
        <MyCreateContext.Provider value={{ login, changeLogin }}>
            {props.children}
        </MyCreateContext.Provider>
    )
}

export default HandleAllApi