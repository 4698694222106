import React from 'react';
import img from '../img/load-bg.gif';

const NewLazy = () => {
    return (
        <div className="verify-opoup">
            <div className="popup-content">
                <img src={img} alt="please wait..." />
                <h3 className='mb-0 mt-3 p-0'>please wait...</h3>
            </div>
        </div>
    )
}

export default NewLazy
