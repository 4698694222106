import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import img from "../img/logo.png";
import my from "../img/dummy.jpg";
import { MyCreateContext } from '../ContextApi/HandleAllApi';

const Header = () => {
    const context = useContext(MyCreateContext);

    const logout = () => {
        localStorage.setItem("google_verify_login_auth", "")
        localStorage.setItem("google_verify_login_auth_count", 0)
        context.changeLogin(false, "", "", "", "", "", "", "", "", "", [{ linkedin: '', github: '', facebook: '', website: '' }], "")
    }

    return (
        <header className='fixed'>
            <nav className="navbar navbar-expand-lg bg-transparent">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={img} alt="SoftingArt" title="SoftingArt" />
                    </Link>
                    <button className="navbar-toggler open-close-nav" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" aria-modal="true" role="dialog">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                                <Link className="navbar-brand" to="/">
                                    <img src={img} alt="SoftingArt" title="SoftingArt" />
                                </Link>
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav ms-auto">
                                {!context.login.login
                                    ?
                                    <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/">Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/about-us">About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/courses">All Courses</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/contact">Contact</NavLink>
                                        </li>
                                    </>
                                    :
                                    <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/dashboard">Dashboard</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/courses">All Courses</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/my-course">My Courses</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/contact">Support</NavLink>
                                        </li>
                                    </>
                                }
                            </ul>
                            <div className="d-flex">
                                {
                                    context.login.login
                                        ?
                                        <div className='profile-nav'>
                                            <button className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={(context.login.image === "") ? my : "https://api.softingart.co.in/files/images/" + context.login.image} title={context.login.name} alt={context.login.name} />
                                                <p>{context.login.name}</p>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end border">
                                                <li><NavLink to={"/profile"} className="dropdown-item">Profile</NavLink></li>
                                                {(context.login.type === "admin") || (context.login.type === "mentor") ? <li><NavLink to={"/admin"} className="dropdown-item">Admin Home</NavLink></li> : ""}
                                                <li><button onClick={logout} className="dropdown-item logout"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp; Logout</button></li>
                                            </ul>
                                        </div>
                                        :
                                        <NavLink className="btn btn-primary" to="/login">Login / Register</NavLink>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
